@import "./abstracts/theme.module";

:root {
  @include printVariables();
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-weight: normal;
  font-style: normal;
  color: var(--white) !important;
  font-size: 17px;
  line-height: 1.5;
  background-color: var(--primaryBlue) !important;
  min-height: 100vh;
}

b,
strong {
  font-weight: 700;
}

p {
  margin: 0 0 22px 0;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  li {
    list-style: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 25px 0;
  padding: 0;
  color: #000;
  font-weight: 700;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

h2 {
  font-weight: 700;
  font-size: 80px;
  line-height: 1;
}

h4 {
  font-size: 24px;
  line-height: 1.2;
}

a {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  outline: none !important;
  color: #ecb61e;

  transition: all 0.3s ease-in-out;
}

a:hover {
  color: red;
}

img {
  max-width: 100%;
}

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="password"],
input[type="email"],
select {
  background-color: transparent;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  color: #000;
  border: 1px solid rgba(255, 255, 255, 0.7);
  height: 58px;
  padding: 0 16px;
  outline: none !important;
}

textarea {
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;

  border-radius: 0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 12px 16px;
  width: 100%;
  color: #000;
  height: 158px;
  outline: none !important;
}

select {
  border: 1px solid rgba(255, 255, 255, 0.7);
  width: 100%;
  padding: 0 11px;
  // background: url(images/arrow_bottom.svg) no-repeat calc(100% - 16px) center transparent;
  background-size: 18px;
  padding-right: 40px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

input[type="submit"] {
  background: #eab71e;
  color: #000000;
  height: 58px;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="submit"]:hover {
  background: #000;
  color: #eab71e;
}

::-webkit-input-placeholder {
  opacity: 0.7;
  color: #000;
}

:-ms-input-placeholder {
  opacity: 0.7;
  color: #000;
}

::-moz-placeholder {
  opacity: 0.7;
  color: #000;
}

:-moz-placeholder {
  opacity: 0.7;
  color: #000;
}

// @media (min-width: 1200px) {
//   .container {
//     max-width: 84%;
//     margin: auto;
//     padding: 0 15px;
//   }
// }

// @media (max-width: 1199px) {
//   .container {
//     max-width: 960px;
//     margin: auto;
//   }
// }

// @media (max-width: 991px) {
//   .container {
//     max-width: 720px;
//     margin: auto;
//   }
// }

// @media (max-width: 767px) {
//   .container {
//     max-width: 540px;
//     margin: auto;
//     padding: 0 15px;
//   }
// }

.MuiContainer-root {
  padding: 0 15px;
  // @media (min-width: 1200px) {
  //   max-width: 1200px;
  // }

  @media (min-width: 1600px) {
    max-width: 1260px;
    margin: 0 auto;
  }
}

.cmn_gap {
  padding: 100px 0;

  @media (max-width: 1199px) {
    padding: 70px 0;
  }

  @media (max-width: 575px) {
    padding: 50px 0;
  }
}

.cmn_head {
  font-weight: 800;
  font-size: 48px;
  line-height: 1.1;
  letter-spacing: 0.01em;
  color: var(--white);
  // text-transform: Capitalize;

  @media (max-width: 1199px) {
    font-size: 40px;
  }

  @media (max-width: 991px) {
    font-size: 30px;
  }
}

.pink_text {
  color: var(--activeColor);
}

.text-center {
  text-align: center;
}

#w3m-modal {
  &.w3m-overlay {
    z-index: 99 !important;
    overflow: hidden;
  }
}
.MuiTypography-root {
  &p {
    @media (max-width: 599px) {
      font-size: 14px;
    }
  }
}
.mobile-none {
  @media (max-width: 599px) {
    display: none !important;
  }
}
.desktop-none {
  display: none !important;
  @media (max-width: 599px) {
    display: block !important;
  }
}
.midle-align {
  text-align: center;
}
.vesting_grp {
  .MuiFormControlLabel-root {
    justify-content: space-between !important;
    @media (max-width: 1199px) {
      justify-content: start !important;
    }
  }
}
.hidden {
  overflow-y: hidden !important;
}

.transaction-toast {
  * {
    margin: 0 !important;
  }
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: min(calc(100vw - 2rem), 400px);

  & h3 {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid #757575;
  }

  & a {
    color: #fff;
    text-decoration: underline !important;
    font-size: 0.85rem;
  }
}
